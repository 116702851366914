import {
  OrgId,
  OrgInvoice,
  OrgInvoiceChild,
  OrgInvoiceParent,
  OrgInvoiceParentWithChildAndPaymentData,
  OrgInvoiceTypes,
  OrgPayment,
  OrgPaymentType
} from "@ollie-sports/models";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { firestoreLiftQuerySubToBifrostSub } from "../../internal-utils/firestoreLiftSubToBifrostSub";
import { validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";
import * as express from "express";
import _ from "lodash";
import { isParentOrgInvoice } from "../../utils";

const SortObj: { [str in string]?: true } = {
  name: true,
  type: true,
  amount: true,
  date: true,
  memo: true,
  category: true,
  failureCode: true
};

export async function orgInvoice__server__getOrgPaymentsForOrg(p: {
  orgId: OrgId;
  selfAccountId: string;
  offset?: number;
  limit?: number;
  searchTerm?: string;
  type?: OrgPaymentType[];
  startDate?: number;
  endDate?: number;
  category?: "registration" | "invoice";
  failureCode?: number[];
  sort?: { key: string; dir: "asc" | "desc" }[];
}) {
  const { getAppPgPool } = getServerHelpers();

  const baseParam = {
    orgId: { valid: true, val: p.orgId },
    offset: { valid: true, val: p.offset || 0 },
    searchTerm: { valid: !!p.searchTerm, val: `%${p.searchTerm?.replace(/ /g, "").toLowerCase()}%` },
    limit: { valid: true, val: p.limit || 100 },
    type: { valid: !!p.type?.length, val: p.type },
    startDate: { valid: !!p.startDate, val: p.startDate },
    endDate: { valid: !!p.endDate, val: p.endDate },
    category: { valid: !!p.category, val: p.category }
  };

  const queryParams = _(baseParam)
    .entries()
    .filter(a => !!a[1].valid)
    .map((a, i) => {
      return [a[0], { sqlParam: `$${i + 1}`, value: a[1].val }];
    })
    .fromPairs()
    .value() as { [k in keyof typeof baseParam]?: { sqlParam: string; value: any } };

  const sort = p.sort?.filter(a => SortObj[a.key] && ["asc", "desc"].includes(a.dir)).slice(0, 3) || [
    { key: "date", dir: "desc" }
  ];
  const orderBy = sort
    .map(a => {
      if (a.key === "date") {
        return `a.org_payment ->> 'createdAtMS' ${a.dir}`;
      } else if (a.key == "amount") {
        return `(a.org_payment->>'amountCents')::integer + coalesce((a.org_payment->>'lateFeeAmountCents')::integer, 0) + coalesce((a.org_payment->>'processingFeeAmountCents')::integer, 0) ${a.dir}`;
      } else if (a.key === "type") {
        return `a.org_payment ->> 'type' ${a.dir}`;
      } else if (a.key === "name") {
        return `name ${a.dir}`;
      } else if (a.key === "category") {
        return `category ${a.dir}`;
      } else {
        return "";
      }
    })
    .filter(Boolean)
    .join(", ");

  const query = `select a.org_payment,
                        a.org_invoice,
                        a.name,
                        a.team_short_name,
                        a.team_name,
                        oi2.item as parent_org_invoice,
                        a.count as count
    from (select
            op.item as org_payment,
            oi.item as org_invoice,
            case when oi.item ->> 'type' ilike '%registration%' then 'registration' else 'invoice' end as category,
            count(*) OVER () AS count,
            concat(trim(pb.item -> 'virtualAthleteAccount' ->> 'lastName'), ', ', trim(pb.item -> 'virtualAthleteAccount' ->> 'firstName')) as name,
            t.item->>'shortName' as team_short_name,
            t.item->>'name' as team_name
          from mirror_orgpayment op
            join
               mirror_orginvoice oi on oi.id = op.item ->> 'invoiceId'
            join
               mirror_playerbundle pb on pb.id = op.item ->> 'playerBundleId'
            left join
              mirror_orgregistration r on r.item ->> 'orgInvoiceId' = oi.item ->> 'invoiceGroupId'
            left join
              mirror_team t on r.item -> 'relevantPackageInfoSnapshot' ->> 'teamIdThatDeterminedPackage' = t.id
          where
            op.item ->> 'orgId' = ${queryParams.orgId!.sqlParam}
            ${queryParams.startDate ? `and op.item->>'createdAtMS' > ${queryParams.startDate.sqlParam}` : ""}
            ${queryParams.endDate ? `and op.item->>'createdAtMS' < ${queryParams.endDate.sqlParam}` : ""}
            ${queryParams.type ? `and op.item->>'type' = ANY(${queryParams.type.sqlParam}::text[])` : ""}
            ${
              queryParams.category
                ? `and case when oi.item ->> 'type' ilike '%registration%' then 'registration' else 'invoice' end = ${queryParams.category.sqlParam}`
                : ""
            }
          ) a
      left join mirror_orginvoice oi2 on oi2.id = a.org_invoice ->> 'parentOrgInvoiceId'
    ${queryParams.searchTerm ? `where name ilike ${queryParams.searchTerm.sqlParam}` : ""}
    order by ${orderBy}
    OFFSET ${queryParams.offset!.sqlParam} LIMIT ${queryParams.limit!.sqlParam};`;

  const r1 = await getAppPgPool().query(
    query,
    Object.values(queryParams).map(a => a.value)
  );

  if (!r1.rowCount) {
    return {
      data: [],
      count: 0
    };
  } else {
    return {
      data: r1.rows.map(row => {
        return {
          orgPayment: row["org_payment"] as OrgPayment,
          orgInvoice: row["org_invoice"] as OrgInvoice,
          parentOrgInvoice: row["parent_org_invoice"] as OrgInvoiceParent | undefined,
          playerName: row.name as string,
          teamShortName: (row.team_short_name as string) || "",
          teamName: (row.team_name as string) || ""
        };
      }),
      count: (r1.rows[0].count ?? 0) as number
    };
  }
}

orgInvoice__server__getOrgPaymentsForOrg.auth = async (r: express.Request) => {
  // Make sure user has correct permission
  await validateTokenAndEnsureSelfAccountIdMatches(r);
};

// i18n certified - complete
